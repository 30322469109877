.video-container {
  text-align: center;
  width: 100vw;
  height: 100vh;
  background-color: black;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-container video {
  width: 100%;
  height: 100%;
  max-width: 600px;
  max-height: 337px;
  background-color: black;
}

.on-filter-video {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: transparent;
  top: 0;
  left: 0;
}
